var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      {
        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
        attrs: { topClass: "topcolor-orange" },
      },
      [
        _c(
          "c-table",
          {
            ref: "table",
            attrs: {
              title: "수급업체 평가 항목 목록",
              parentProperty: "pid",
              customID: "itemNo",
              columns: _vm.grid.columns,
              data: _vm.grid.data,
              merge: _vm.grid.merge,
              gridHeight: _vm.grid.height,
              cardClass: "topcolor-orange",
              usePaging: false,
              rowKey: "itemSeq",
            },
          },
          [
            _c(
              "template",
              { slot: "table-chip" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _c(
                      "q-chip",
                      [
                        _c("q-avatar", {
                          attrs: {
                            icon: "bookmark",
                            color: "orange",
                            "text-color": "white",
                          },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.vendorEval.totalGrade +
                                " / " +
                                _vm.vendorEval.evalToatlGrade +
                                " / " +
                                _vm.vendorEval.grade
                            ) +
                            " "
                        ),
                        _c("q-tooltip", [
                          _vm._v(_vm._s("총점 / 평점 / 등급 ")),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }